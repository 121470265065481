export function capitalize(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export function trimPropertyName(name: string) {
  const property = name.replace('BDN.', '')
  return property.charAt(0).toLowerCase() + property.slice(1)
}

export function replaceString(str: string, stringToReplace: string, replacement: string) {
  return str.includes(stringToReplace) ? str.replace(stringToReplace, replacement) : str

}
