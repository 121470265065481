import { Duration, DurationLikeObject, ToHumanDurationOptions } from 'luxon'

interface ToHumanOptions {
  precision?: { [unit: string]: number }
  smallestUnit?: keyof DurationLikeObject
  biggestUnit?: keyof DurationLikeObject
  maxUnits?: number
  stripZeroUnits?: 'all' | 'end'
}

export function toCustomHumanDuration(
  duration: Duration,
  opts: ToHumanOptions = {}
): string {
  let normalizedDuration = duration.normalize()
  let durationUnits: (keyof DurationLikeObject)[] = []
  let precision: Duration | undefined

  if (typeof opts.precision === 'object') {
    precision = Duration.fromObject(opts.precision)
  }

  console.log(duration.rescale().toHuman(), 'duration')
  console.log(normalizedDuration.rescale().toHuman(), 'normalized')
  let remainingDuration = normalizedDuration
  const allUnits = [
    'years',
    'months',
    'days',
    'hours',
    'minutes',
    'seconds',
    'milliseconds'
  ] as (keyof DurationLikeObject)[]
  let smallestUnitIndex = -1
  let biggestUnitIndex = -1

  if (opts.smallestUnit) {
    smallestUnitIndex = allUnits.indexOf(opts.smallestUnit)
  }
  if (opts.biggestUnit) {
    biggestUnitIndex = allUnits.indexOf(opts.biggestUnit)
  }

  if (!(smallestUnitIndex >= 0 && smallestUnitIndex < allUnits.length)) {
    smallestUnitIndex = allUnits.indexOf('seconds')
  }
  if (
    !(
      biggestUnitIndex <= smallestUnitIndex &&
      biggestUnitIndex < allUnits.length
    )
  ) {
    biggestUnitIndex = allUnits.indexOf('years')
  }
  console.log(biggestUnitIndex)
  for (const unit of allUnits.slice(biggestUnitIndex, smallestUnitIndex + 1)) {
    const durationInUnit = remainingDuration.as(unit)
    if (durationInUnit >= 1) {
      durationUnits.push(unit)
      const temp: { [unit: string]: number } = {}
      temp[unit as string] = Math.floor(remainingDuration.as(unit))
      remainingDuration = remainingDuration
        .minus(Duration.fromObject(temp))
        .normalize()

      if (precision && remainingDuration < precision) {
        break
      }
    }

    if (opts.maxUnits && durationUnits.length >= opts.maxUnits) {
      break
    }
  }

  normalizedDuration = normalizedDuration.minus(remainingDuration).normalize()
  normalizedDuration = normalizedDuration.shiftTo(...durationUnits)

  if (opts.stripZeroUnits === 'all') {
    durationUnits = durationUnits.filter(
      (unit) => normalizedDuration.get(unit) > 0
    )
  } else if (opts.stripZeroUnits === 'end') {
    let mayStrip = true
    durationUnits = durationUnits.reverse().filter((unit) => {
      if (!mayStrip) return true
      if (normalizedDuration.get(unit) === 0) {
        return false
      } else {
        mayStrip = false
      }
      return true
    })
  }

  return normalizedDuration
    .shiftTo(...durationUnits)
    .toHuman(opts as ToHumanDurationOptions)
}
