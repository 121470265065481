import { CompanyChangeRequest, CompanyContactChangeRequest, CompanyProfileChangeRequest, Requester } from "../accounting"

export interface ProcessResponse {
  processId: number
  status: ProcessStatus
}

export interface ProcessStatus {
  code: number
  description: Description
  messages: Message[]
}

export interface Description {
  key: string
  text: string
}

export interface Message {
  code: number
  description: Description
  level: string
  timestamp: string
  note: string
}

export interface Process {
  id: number
  userId: number
  status: ProcessStatus
  active: boolean
  operation: string
  type: string
  timestamp: string
  runtime: string
}

export class ActivateElectronicModuleRequest {
  public constructor(
    public electronicModuleId: number,
    public macAddress: string,
    public deviceAddress: number,
    public protocol: string,
    public modbus: ModbusSettings | null
  ) {
  }

}


export interface ModbusSettings {
  baudRate: number
  dataBits: number
  parity: string
  stopbits: number
}

export class TransitionRequest {
  constructor(
    public company: CompanyChangeRequest,
    public profile: CompanyProfileChangeRequest,
    public contact: CompanyContactChangeRequest,
    public requester: Requester
  ) {
  }
}

export interface OnboardRequest {
  requester: Requester
  company: CompanyChangeRequest
  profile: CompanyProfileChangeRequest
  contact: CompanyContactChangeRequest
  accounts: AccountInfoChangeRequest[]
}

export interface AccountInfoChangeRequest {
  firstName: string
  lastName: string
  email: string
  entitlement: EntitlementChangeRequest
}

export interface EntitlementChangeRequest {
  userLevel: string
  roles: string[]
}
