import { AccessEntryItem } from "../access"
import { EquipmentEntry } from "../equipment/equipment.api"
import { ResourceHierarchyNode } from "../hierarchy"
import { Page } from "../shared.api"
import { AlarmLevelEntry, EventState } from "../site"

export interface System {
  id: number,
  name: string,
  externalId: string,
  ownerId: number,
  type: string,
  refrigerant: string,
  ainResourceId: string,
  timezone: string
}

export interface SystemInfo {
  system: System,
  eventState: EventState,
  alarmState: AlarmLevelEntry[]
}

export class SystemUpdateRequest {
  constructor(
    public name = "",
    public refrigerant = "",
    public timezone = "",
    public type = ""
  ) {
  }
}

export interface SystemDetails {
  system: System,
  access: AccessEntryItem,
  hierarchy: ResourceHierarchyNode,
  equipments: Page<EquipmentEntry>,
}
