import { PropertyEntry } from "../property/property.api"
import { AlarmLevelEntry } from "../site"
import { EventState } from "../site/state-api"

export interface Equipment {
  id: number,
  name: string,
  externalId: string,
  ainResourceId: string,
  sapModelId: string,
  sapThingId: string,
  thingId: number,
  serialNumber: string,
  productId: string,
  timeInFault: string,
  refrigerant: string,
  activated: boolean,
  activatedOn: string,
  registeredOn: string
}

export interface EquipmentInfo {
  equipment: Equipment,
  eventState: EventState,
  alarmState: AlarmLevelEntry[],
}

export class EquipmentUpdateRequest {
  constructor(
    public name = "",
    public refrigerant = "",
    public type = "",
    public serialNumber = "",
    public electronicModuleType = "",
    public electronicModuleSerialNumber = ""
  ) {
  }
}

export interface EquipmentEntry {
  equipment: EquipmentInfo,
  properties: PropertyEntry[]
}
